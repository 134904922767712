@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sprat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chenla:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  color: white;

}


html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
  /* background-color: #3e1e3f; */
/* background: linear-gradient(180deg, #000A3C 0%, #000E57 100%); */
background-color: #000A3C;
background-color: #000A3C;

 /* background: linear-gradient(180deg, #000A3C 0%, #000E57 100%); */
 background: linear-gradient(to bottom,#000A3C, #000A3C);
}



.index{

  /* background-color: #000A3C; */
}

.app{
  position: relative;
  /* overflow: hidden; */
  /* background-color: #000A3C; */

  
}

.bg-top{
  position: absolute;

  z-index: -1;
  top: 0; 
  left: 0;
  overflow: hidden;

}

.bg-top>img{
  width: 200%;
}

.bg-middle{
  
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  

}

.bg-middle>img{
  width: 100%;
}



.bg-bottom{
  
  position: absolute;

z-index: -1;
bottom: 0;
right: 0;
overflow: hidden;
}

.bg-bottom>img{
  width: 100%;
}

.heading{
  font-family: 'Tenor Sans', sans-serif;
  
}


.container{
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  margin-top: 3rem;
}


.arrow1{
  display: block;
}


.arrow2{
  display: none;
}

@media screen and (min-width: 700px) {


  .bg-top>img{
    width: 90vw;
  }

  .bg-bottom>img{
    width: 50vw;
  }

  .container{
    margin-top: 6rem;

   row-gap: 6rem;
  }

  .arrow1{
    display: none;
  }
  
  
  .arrow2{
    display: block;
  }
  

}