.hero {
  width: 90%;
  margin: 0 auto;
  color: white;
  max-width: 1200px;
  margin-inline: auto;
}
.hero .hero1 {
  display: flex;
  flex-direction: column;
}
.hero .hero1 .fox {
  width: 80%;
  display: block;
  margin-inline: auto;
}
.hero .hero1 .text {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: end;
}
.hero .hero1 .text .heading {
  width: -moz-fit-content;
  width: fit-content;
  text-align: start;
  align-self: flex-start;
  width: 90%;
  max-width: 400px;
}
.hero .hero1 .text .heading .heading2 {
  font-size: 1.3rem;
  font-family: "Tenor Sans", sans-serif;
}
.hero .hero1 .text .heading .diamond-line {
  width: 110%;
  position: relative;
  left: -10px;
}
.hero .hero1 .text .section {
  text-align: start;
  padding-left: 0.5rem;
}
.hero .hero2 .section {
  max-width: 700px;
  text-align: center;
  margin: 2rem auto;
}
.hero .hero2 button {
  background: linear-gradient(87deg, #0085FF -11.82%, #001AFF 97.89%);
  margin-top: 2rem;
  display: block;
  margin-inline: auto;
  padding: 0.7rem 2.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1.1rem;
}
.hero .hero2 .fox-meme {
  display: block;
  width: 80%;
  margin-top: 2rem;
  margin-inline: auto;
  max-width: 500px;
}
.hero .heading {
  font-weight: 100;
  text-transform: uppercase;
}

@media screen and (min-width: 700px) {
  .hero .hero1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .hero .hero1 .text .heading .heading2 {
    font-size: 1.5rem;
  }
  .hero .hero1 .text .section {
    margin-top: 1rem;
    padding-left: 2rem;
  }
}